* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Helvetica neue", Helvetica, Arial,"Lucida Grande", sans-serif;
  background-color: black;
}

header {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  /* padding: 20px; */
  color: greenyellow;
  font-size: 300%;
}

main {
  display: flex;
  padding: 2rem;
  background-color: black;
}


h1 {
  color: greenyellow;
  font-family: Garamond, Baskerville, "Times New Roman",serif;
}

h1::after {
  content: 'Feltman';
  color:orange;
  margin-left: 10px;
}

nav {
  display: table-cell;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 95%;
} 

nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  font-size: 50%;
}

nav a {
  justify-content: space-between;
  align-items: center;
  color: greenyellow;
  margin-inline: 20px;
  text-decoration: none;
  font-weight: bold;
}

nav a.active {
  text-decoration: underline;
}

nav a:hover {
  color: lavender;
  font-size: larger;
  font-weight: bold;
}
.animate-character
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 190px;
      margin-top: -45px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.animate-character
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 100px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.about-text-photo {
  display: flex;
  flex-direction: column;
}

#about-text {
  flex-direction: row;
  color: white;
  font-size: 25px;
  font-weight: bold;
  line-height: 40px
}

#about-image {
  position: relative;
  height: 300px;
  left: 880px;
  align-items: flex-end;
  margin-top: -115px;
}

#left {
  background-color: navy;
  color:greenyellow;
  width: 75%;
}

#left h2 {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  margin-top: 40px;
}

#left section {
  color:greenyellow;
  text-align: center;
}

#left section img {
  width: 300px;
  height: 168px;
}

#left .grow:hover {
  transform: rotate(15deg) scale(1.2);
}

#left section h3 {
  text-align: center;
  font-size: 25px;
}


aside {
  background-color: greenyellow;
  width:25%;
  font-weight: bold;
  color: black;
}

aside h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 16px;
}

aside section {
  font-size:14px;
  line-height: 1.6rem;
  padding:40px;
}

aside #resume {
  text-align: center;
}

aside img {
  max-width: 100%;
}

.title {
  color: lime
}

#resume {
  width: 65%;
  height: 100%;
}

iframe {
  height: 750px;
  width: 100%;
}

.resume-profs {
  display: flex;
  flex-direction: block;
  width: 100%;
  height: 800px;
}

.listStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 750px;
  justify-content: space-between;
}

.listStyle div {
  margin-bottom: 1rem;
}

.listStyle div:first-of-type {
  margin-top: 1rem;
}

.listStyle div img {
  max-height: 80px;
}

.logoSize {
  height: 25px;
  width: 25px;
}

.profs {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: limegreen;
  width: 25%;
}

#title-text {
  font-family: 'Paytone One', sans-serif;
  font-size: 75px;
  background: -webkit-linear-gradient(rgb(179, 95, 218), rgb(22, 11, 175));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  margin-top: -5px;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin-left: 330px;
  line-height: 95px;
  color: white;
}

textarea {
  height: 200px;
  width: 450px;
  margin-top: 15px;
  border: solid;
  border-color: purple;
  border-width: 10px;
  background-color: gainsboro;
  color: black;
}

input {
  height: 60px;
  width: 450px;
  border: solid;
  border-color: purple;
  border-width: 10px;
  background-color: gainsboro;
  color: black;
}

label {
  display: block;
  margin-bottom: -30px;
  font-size: 30px;
}

.submitBtn {
    padding: 15px 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
    font-size: 40px;
    color: limegreen;
    background-color: black;
    border: solid;
}

.submitBtn:hover {
  color: black !important;
  background-color: limegreen !important;
  border: solid;
}

span {
  margin-left: 15px;
}

footer {
  display: flex;
  background-color: black;
  color: limegreen;
  font-size: 17px;
  padding: 10px;
  margin-top: 2rem;
}

footer a {
  color: limegreen;
}

footer a:hover {
  color: greenyellow;
}

footer h2 {
  width: 20%;
  font-size: 20px;
}

footer ul {
  display: flex;
  justify-content: space-around;
  color: limegreen;
  width: 200%;
  align-items: center;
  list-style: none;
}

#workTitle {
  color: limegreen;
  text-align: center; 
  font-size: 75px;
}

#work {
  display: contents;
  flex: 4;
  /* background-color: (lavender); */
  padding: 50px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0px 0px;
  color: limegreen;
  border-color: limegreen;
}

.grow {
  width: 1200px;
  flex-direction: column;
  border-style: solid;
  border-width: 5px;
  padding: 10px;
  margin: 32px;
  flex: 0 0 300px;
  border-radius: 25px;
  /* background-color: purple; */
  box-shadow: 10px 5px 5px green;
}

@media screen and (max-width: 1180px) {
  header {
      flex-direction: column;
      align-items: initial;
  }
  nav {
      display: block;
      margin-top: 20px;
  }
  nav ul {
      justify-content: left;
  }
  nav a {
      margin-inline: 0;
      margin-right:20px;
  }

  aside {
      width: 30%;
  }

  #left {
      width: 70%;
  }
}

@media screen and (max-width: 768px) {
  main {
      flex-direction: column;
  }

  aside {
      width: 100%;
  }

  #left {
      width: 100%;
  }

  footer {
      text-align: center;
      flex-direction: column;
  }
  footer h2 {
      width:100%;
  }
  footer ul {
      width:100%;
      flex-direction: column;
  }
}

.project-image {
  max-width: 250px;
}
